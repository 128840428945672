@import '../../../styles/customMediaQueries.css';


.spacesContainer {
  display: flex;
  gap: 1rem;
  max-width: 100vw;
  /* margin-block: 10px; */
  padding-inline: 10px;
  padding-block: 7px;
  overflow-x: scroll;

  @media (--viewportMedium) {
    max-width: 450px;
    padding: 1rem;
  }


  & .space {
    padding: 15px 20px;
    border-radius: 5px;
    min-width: 155px;
    /* height: 80px; */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    /* background: #fff; */
    box-shadow: 0 0 15px rgba(0, 0, 0, .1);
    margin-right: 20px;

    & .price {
      font-weight: 900;
      font-size: 1.2rem;
      color: var(--marketplaceColor);
      margin: 0;
      padding: 0;
    }

    & .sleeping {
      margin: 0;
      padding: 0;
      margin-top: 10px;
      font-weight: 700;
      text-align: center;
    }

    & .spaceType {
      font-size: 0.9rem;
      color: gray;
      font-weight: 600;
      margin-top: 10px;
    }

    & .discountLabel {
      border-top: solid 1px #8a8a8a;
      color: #8a8a8a;
      font-size: 14px;
      text-align: center;
      line-height: 1;
      padding: 4px;
    }

    &:hover {
      transform: translateY(-5px);
      transition: all 0.4s ease;
      box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.4);
    }
  }

  & .active {
    background-color: var(--marketplaceColor);
    color: #ffff;

    & .price,
    .sleeping,
    .spaceType,
    .discountLabel {
      color: #fff;
    }
  }

  & .disabled {
    cursor: not-allowed;
    background-color: gray;

    & .price,
    .sleeping,
    .spaceType {
      color: #fff;
    }

    transform: none !important;
  }
}

.wdp-ribbon {
  display: inline-block;
  padding: 2px 15px;
  position: absolute;
  right: 0px;
  top: 3px;
  line-height: 24px;
  /* height: 24px; */
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  border-radius: 0;
  text-shadow: none;
  font-weight: normal;
  background-color: rgb(0, 160, 13) !important;
  color: #fff;
}

.wdp-ribbon-two:before,
.wdp-ribbon-two:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -14px;
  top: 0;
  border: 9px solid transparent;
  border-width: 14px 8px;
  border-right-color: rgb(0, 160, 13);
}

.wdp-ribbon-two:before {
  border-color: rgb(0, 160, 13);
  border-left-color: transparent !important;
  left: -9px;
}